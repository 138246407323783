;(function(global){
    if(!global['lgkorUI']) global['lgkorUI'] = {};

    function getLoginFlag(){
        return $('html').data('login') == 'Y' || $('.support-home').data('login') == 'Y' ? true : false;
    }
    
    var csUI = {
        isLogin: getLoginFlag(),
        cookie: {
            setCookie: function(cookieName, value, expire, deleteCookie) {
                var cookieText;
                var cookieExpire = new Date();

                if (deleteCookie) {
                    cookieExpire = new Date(1);
                } else {
                    cookieExpire.setDate(cookieExpire.getDate() + expire);
                }
                cookieText = cookieName + '=' + escape(value) + ((expire == null) ? '' : '; EXPIRES=' + cookieExpire.toUTCString()) + '; PATH=/';

                document.cookie = cookieText;
            },
            getCookie: function(cookieName) {
                var cookieValue = null;

                if (document.cookie) {
                    var cookieKey = escape(cookieName) + "="; 
                    var cookieArr = document.cookie.split(";");

                    for (var i = 0; i < cookieArr.length; i++) {
                        if(cookieArr[i][0] === " ") {
                            cookieArr[i] = unescape(cookieArr[i].substring(1));
                        }
                        if(cookieArr[i].indexOf(cookieKey) === 0) {
                            cookieValue = unescape(cookieArr[i].slice(cookieKey.length, cookieArr[i].length));
                        }
                    }
                }

                return cookieValue;
            },
            deleteCookie: function(cookieName, value) {
                var cookie = csUI.cookie;
                var cookies = cookie.getCookie(cookieName);

                if (cookies) {
                    var cookieArr = cookies.split(',');
                    
                    if (cookieArr.indexOf(value.toString()) != -1) {
                        var index = -1;
                        for (var i = 0; i < cookieArr.length; i++) {
                            if (value == cookieArr[i]) {
                                index = i;
                                break;
                            }
                        }
                        
                        if (index != -1) {
                            cookieArr.splice(index, 1);
                            cookies = cookieArr.join(',');
                            cookie.setCookie(cookieName, cookies, 365);
                        }
                    }
                }
            },
            deleteAllCookie: function(cookieName) {
                var cookieValue = this.getCookie(cookieName);
                if (cookieValue) {
                    this.setCookie(cookieName, cookieValue, '', true);
                }
            }
        },
        recentlySearch: {
            cookieName: 'LG_SupportSearch',
            maxNum: 10,
            expire: 30,
            addCookie: function(value) {
                var self = this;
                var cookie = csUI.cookie;
                var cookies = cookie.getCookie(self.cookieName);

                if (cookies) {
                    var cookieArr = cookies.split(',');

                    if (cookieArr.indexOf(value) != -1) {
                        cookie.deleteCookie(self.cookieName, value);
                        cookieArr.splice(cookieArr.indexOf(value), 1);
                        cookieArr.unshift(value);
                    } else {
                        cookieArr.unshift(value);
                        if (cookieArr.length > self.maxNum) cookieArr.length = self.maxNum;
                    }
                    cookies = cookieArr.join(',');
                    cookie.setCookie(self.cookieName, cookies, self.expire);
                } else {
                    cookie.setCookie(self.cookieName, value, self.expire);
                }
            }
        },
        recentlyKeyword: {
            cookieName: 'LG_SupportKeyword',
            maxNum: 5,
            expire: 30,
            addCookie: function(value) {
                var self = this;
                var cookie = csUI.cookie;
                var cookies = cookie.getCookie(self.cookieName);

                if (cookies) {
                    var cookieArr = cookies.split(',');

                    if (cookieArr.indexOf(value) != -1) {
                        cookie.deleteCookie(self.cookieName, value);
                        cookieArr.splice(cookieArr.indexOf(value), 1);
                        cookieArr.unshift(value);
                    } else {
                        cookieArr.unshift(value);
                        if (cookieArr.length > self.maxNum) cookieArr.length = self.maxNum;
                    }
                    cookies = cookieArr.join(',');
                    cookie.setCookie(self.cookieName, cookies, self.expire);
                } else {
                    cookie.setCookie(self.cookieName, value, self.expire);
                }
            }
        },
        initProductSlider: function() {
            // 관련 소모품이 필요하신가요?
            $('.product-slider').vcCarousel({
                infinite: false,
                autoplay: false,
                slidesToScroll: 4,
                slidesToShow: 4,
                cssEase: 'cubic-bezier(0.33, 1, 0.68, 1)',
                speed: 150,
                touchThreshold: 100,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToScroll: 3,
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            arrows: false,
                            slidesToScroll: 1,
                            slidesToShow: 1,
                            variableWidth: true
                        }
                    },
                    {
                        breakpoint: 20000,
                        settings: {
                            slidesToScroll: 4,
                            slidesToShow: 4
                        }
                    }
                ]
            });
        },
        searchParamsToObject: function(key) {
            var params = location.search.substr(location.search.indexOf("?") + 1);
            var temp, valueObject = {};

            params = params.split("&");
            
            for (var i = 0; i < params.length; i++) {
                temp = params[i].split("=");
                valueObject[temp[0]] = temp[1];
            }

            if (key) {
                return valueObject[key] || null;
            } else {
                return valueObject;
            }
        },
        historyBack: function(item) {
            var url;
            if (item.constructor == Object) {
                url = $.param(item);
            } else {
                url = $(item).attr('href');
                url = url.substr(url.indexOf('?') + 1);
            }
            history.replaceState(null, '', '?'+url);
        },
        scrollTo: function($target, margin) {
            if ($target.length) {
                $('html,body').stop().animate({scrollTop:$target.offset().top - (margin ? margin : 0)});

                // BTOCSITE-21157 고객지원 메뉴 - 포커스 이동 위치 수정
                // $('html,body').stop().animate({scrollTop:$target.offset().top - (margin ? margin : 0)}, function() {
                //     $target.focus();
                //     if (!$target.is(':focus')) {
                //         $target.attr('tabindex', -1).focus().removeAttr('tabindex');
                //     }
                //     return false;
                // });
            }
        },
        setAcecounter: function(pcUrl, moUrl) {
            try {
                if (vcui.detect.isMobileDevice) {
                    AM_PL(moUrl);
                } else {
                    _PL(pcUrl);
                }
            } catch(e) {}
        },
        // BTOCSITE-17439 출장서비스예약 > 챗봇 이용 권유를 위한 기능 추가
        getChatPinCodeAsUrl: function(url,popupWidth,popupHeight,intLeft,intTop) {
            var self = this;
            var $url = url
            var loginFlag = digitalData.hasOwnProperty("userInfo") && digitalData.userInfo.unifyId ? "Y" : "N";

                if(!lgkorUI.CHAT_PIN_CODE && loginFlag === 'Y') {
                    lgkorUI.requestAjaxData('/support/getPinCode.lgajax', null, function(result) {
                        var data = result.data;
                        if(data) {
                            var receveResult = data.result;
                            if(receveResult && receveResult.code) {
                                lgkorUI.CHAT_PIN_CODE = receveResult.code;
                            }
                        }
                        
                        var parseUrl = lgkorUI.parseUrl($url),
                            params = $.extend(parseUrl.searchParams.getAll(),{'channel': isApp() ? "lg_app" : "lg_homepage", 'code' :  lgkorUI.CHAT_PIN_CODE || ''});
                        $url = vcui.uri.addParam(parseUrl.origin+parseUrl.pathname,params);
                        if( isApp()) {
                            if(vcui.detect.isIOS){
                                var jsonString = JSON.stringify({'command':'openInAppBrowser', 'url': decodeURIComponent($url), 'titlebar_show': 'N'});
                                webkit.messageHandlers.callbackHandler.postMessage(jsonString);
                            } else {
                                android.openNewWebview($url);
                            }
                        } else {
                            window.open($url, '_blank', 'width=' + popupWidth + ',height=' + popupHeight + ',left=' + intLeft + ',top=' + intTop + ',history=no,resizable=no,status=no,scrollbars=yes,menubar=no');
                        }
                    },"GET", "json", true, null, true);
                } else {
                    var parseUrl = lgkorUI.parseUrl($url),
                        params = $.extend(parseUrl.searchParams.getAll(),{'channel': isApp() ? "lg_app" : "lg_homepage", 'code' :  loginFlag === 'N' ? '' : lgkorUI.CHAT_PIN_CODE});
                    $url = vcui.uri.addParam(parseUrl.origin+parseUrl.pathname,params);

                    if( isApp()) {
                        if(vcui.detect.isIOS){
                            var jsonString = JSON.stringify({'command':'openInAppBrowser', 'url': decodeURIComponent($url), 'titlebar_show': 'N'});
                            webkit.messageHandlers.callbackHandler.postMessage(jsonString);
                        } else {
                            android.openNewWebview($url);
                        }
                    } else {
                        window.open($url, '_blank', 'width=' + popupWidth + ',height=' + popupHeight + ',left=' + intLeft + ',top=' + intTop + ',history=no,resizable=no,status=no,scrollbars=yes,menubar=no');
                    }                    
                }
        },
        // BTOCSITE-17439 출장서비스예약 > 챗봇 이용 권유를 위한 기능 추가
        getChatDomain: function(){
            var referrer = window.location.hostname; // BTOCSITE-17778 Oneview 출장 서비스 접수 시 인증번호 2중 체크 로직 제거
            var url = lgkorUI.parseUrl(referrer)

            if( url.hostname == lgkorUI.DOMAIN_LIST[0] ) {
                return 'https://chat.lge.co.kr';
            }
            if( url.hostname == lgkorUI.DOMAIN_LIST[1]) {
                return 'https://chat-qa.lgechat.co.kr';
            } 
            if ( url.hostname == lgkorUI.DOMAIN_LIST[2]){
                return 'https://chat-st.lgechat.co.kr'
            }
        },
        sendKeyword: function (query, type, typeDetail, unit) {
            //BTOCSITE-81830 [검색] 고객지원 페이지내 검색어 집계
            var site = "web";
            if (isApp()){
                site = "app";     }
            else if (vcui.detect.isMobile){         
                site = "mobile";     
            }
                
            var param = {
                query: query,
                site: site,
                unit: unit || "all",
                type: type,
                typeDetail: typeDetail
            };
            
            var ajaxUrl = "/search/keywordrecord.lgajax";
            if (ajaxUrl){       
                lgkorUI.requestAjaxDataPost(ajaxUrl, param, null, true);     
            }
        },
    }

    lgkorUI = $.extend({}, lgkorUI, csUI);
})(window);

var csKeyword = {
    init: function() {
        var self = this;
        self.keywordModel.init();
        // self.keyword.init();
    },
    keywordModel: {
        el: {
            searchWrap: '.ui_search_model',
            searchInput: '#search',
            recentlyWrap: '.recently-keyword',
            popularWrap: '.popular-keyword',
            autocompleteWrap: '.autocomplete-box',
        },
        init: function() {
            var $context = $(document);
            if (!$context.find('.ui_search_model').length) return;

            var self = this;
            var $searchWrap = $context.find(self.el.searchWrap);
            var $searchInput = $context.find(self.el.searchInput);
            var $recentlyWrap = $context.find(self.el.recentlyWrap);
            var $popularWrap = $context.find(self.el.popularWrap);
            var $autocompleteWrap = $context.find(self.el.autocompleteWrap);
            var url = $searchWrap.data('searchUrl');

            $searchInput.on('keyup', function(e) {
                if (e.keyCode == 13) {  
                    e.preventDefault();
                    var _value = $searchInput.val();
                    lgkorUI.setAcecounter('www.lge.co.kr/acecount/mainSearchClick.do', '/acecount/mainSearchClickm.do');
                    _value = _value.replace(/(<([^>]+)>)/ig,""); //BTOCSITE-5089
                    _value = _value.replace(/<\/([^>]+)/ig,""); //BTOCSITE-5089
                    //location.href = url + encodeURI(_value)
                }
            });

            $searchWrap.find('.btn-search').on('click', function() {
                var _value = $searchInput.val();
                _value = _value.replace(/(<([^>]+)>)/ig,""); //BTOCSITE-5089
                _value = _value.replace(/<\/([^>]+)/ig,""); //BTOCSITE-5089
                lgkorUI.setAcecounter('www.lge.co.kr/acecount/mainSearchClick.do', '/acecount/mainSearchClickm.do');
                //location.href = url + encodeURI(_value)
            });

            $searchWrap.on('keywordClick', function() {
                var _value = $searchInput.val();
                _value = _value.replace(/(<([^>]+)>)/ig,""); //BTOCSITE-5089
                _value = _value.replace(/<\/([^>]+)/ig,""); //BTOCSITE-5089

                // BTOCSITE-15280 고객지원 Main 통합 검색의 자동 완성 기능 추가 요청
                lgkorUI.recentlyKeyword.addCookie(_value);

                //location.href = url + encodeURI(_value)
            });

            $autocompleteWrap.on('click', 'a', function() {
                lgkorUI.setAcecounter('www.lge.co.kr/acecount/mainModelClick.do', '/acecount/mainModelClickm.do');
            });

            $recentlyWrap.on('click', 'a', function() {
                lgkorUI.setAcecounter('www.lge.co.kr/acecount/mainRecentClick.do', '/acecount/mainRecentClickm.do');
            });

            $popularWrap.on('click', 'a', function() {
                lgkorUI.setAcecounter('www.lge.co.kr/acecount/mainPopularClick.do', '/acecount/mainPopularClickm.do');
            });
            // 모델검색
            $searchWrap.search({
                template: {
                    autocompleteList: '<li><a href="#" data-cst-flag="Y" data-category="{{input.category}}" data-sub-category="{{input.subCategory}}" data-model-code="{{input.modelCode}}" data-product-code="{{input.productCode}}" data-category-nm="{{input.categoryNm}}" data-sub-category-nm="{{input.subCategoryNm}}" data-sales-model-code="{{input.salesModelCode}}" tabindex="">{{#raw text}}</a></li>',
                    recentlyList: '<li><a href="#" data-cst-flag="Y" data-category="{{input.category}}" data-sub-category="{{input.subCategory}}" data-model-code="{{input.modelCode}}" data-product-code="{{input.productCode}}" data-category-nm="{{input.categoryNm}}" data-sub-category-nm="{{input.subCategoryNm}}" data-sales-model-code="{{input.salesModelCode}}" tabindex="">{{#raw text}}</a><button type="button" class="btn-delete"><span class="blind">삭제</span></button></li>',
                    keywordList: '<li><a href="#" data-category="{{input.category}}" data-sub-category="{{input.subCategory}}" data-model-code="{{input.modelCode}}" data-product-code="{{input.productCode}}" data-category-nm="{{input.categoryNm}}" data-sub-category-nm="{{input.subCategoryNm}}" data-sales-model-code="{{input.salesModelCode}}" tabindex="">{{#raw text}}</a></li>'
                }
            });
            // BTOCSITE-15280 고객지원 Main 통합 검색의 자동 완성 기능 추가 요청
            $searchWrap.on('autocompleteonmain', function(e, param, url, callback) {
                lgkorUI.requestAjaxData(url, param, function(result) {
                    callback(result);
                });
            });
        }
    },
    keyword: {
        el: {
            searchWrap: '.ui_search',
            searchInput: '#search',
            recentlyWrap: '.recently-keyword',
            popularWrap: '.popular-keyword',
            autocompleteWrap: '.autocomplete-box'
        },
        init: function() {
            var $context = $(document);
            if (!$context.find('.ui_search').length) return;

            var self = this;
            var $searchWrap = $context.find(self.el.searchWrap);
            var $searchInput = $context.find(self.el.searchInput);
            var $recentlyWrap = $context.find(self.el.recentlyWrap);
            var $popularWrap = $context.find(self.el.popularWrap);
            var $autocompleteWrap = $context.find(self.el.autocompleteWrap);
            var url = $searchWrap.data('searchUrl');

            $searchInput.on('keyup', function(e) {
                if (e.keyCode == 13) {  
                    e.preventDefault();
                    var _value = $searchInput.val();
                    lgkorUI.setAcecounter('www.lge.co.kr/acecount/mainSearchClick.do', '/acecount/mainSearchClickm.do');
                    _value = _value.replace(/(<([^>]+)>)/ig,""); //BTOCSITE-5089
                    _value = _value.replace(/<\/([^>]+)/ig,""); //BTOCSITE-5089
                    //location.href = url + encodeURI(_value)
                }
            });

            $searchWrap.find('.btn-search').on('click', function() {
                var _value = $searchInput.val();
                _value = _value.replace(/(<([^>]+)>)/ig,""); //BTOCSITE-5089
                _value = _value.replace(/<\/([^>]+)/ig,""); //BTOCSITE-5089
                lgkorUI.setAcecounter('www.lge.co.kr/acecount/mainSearchClick.do', '/acecount/mainSearchClickm.do');
                //location.href = url + encodeURI(_value)
            });

            $searchWrap.on('keywordClick', function() {
                var _value = $searchInput.val();
                _value = _value.replace(/(<([^>]+)>)/ig,""); //BTOCSITE-5089
                _value = _value.replace(/<\/([^>]+)/ig,""); //BTOCSITE-5089

                // BTOCSITE-15280 고객지원 Main 통합 검색의 자동 완성 기능 추가 요청
                lgkorUI.recentlyKeyword.addCookie(_value);

                //location.href = url + encodeURI(_value)
            });

            $autocompleteWrap.on('click', 'a', function() {
                lgkorUI.setAcecounter('www.lge.co.kr/acecount/mainModelClick.do', '/acecount/mainModelClickm.do');
            });

            $recentlyWrap.on('click', 'a', function() {
                lgkorUI.setAcecounter('www.lge.co.kr/acecount/mainRecentClick.do', '/acecount/mainRecentClickm.do');
            });

            $popularWrap.on('click', 'a', function() {
                lgkorUI.setAcecounter('www.lge.co.kr/acecount/mainPopularClick.do', '/acecount/mainPopularClickm.do');
            });
            // BTOCSITE-15280 고객지원 Main 통합 검색의 자동 완성 기능 추가 요청
            $searchWrap.search({
                template: {
                    autocompleteList: '<li><a href="#{{input}}">{{#raw text}}</a></li>',
                    recentlyList: '<li><a href="#">{{keyword}}</a><button type="button" class="btn-delete"><span class="blind">삭제</span></button></li>',
                    keywordList: '<li><a href="#">{{keyword}}</a></li>'
                }
            });
            // BTOCSITE-15280 고객지원 Main 통합 검색의 자동 완성 기능 추가 요청
            $searchWrap.on('autocompleteonmain', function(e, param, url, callback) {
                lgkorUI.requestAjaxData(url, param, function(result) {
                    callback(result);
                });
            });
        }
    }
}

$(window).ready(function() {
    csKeyword.init();
});

var CS = CS || {};
CS.MD = CS.MD || {};

CS.MD.plugin = function(pluginName, Plugin) {
    $.fn[pluginName] = function(options) {
        var arg = arguments; 

        return this.each(function() {
            var _this = this,
                $this = $(_this),
                plugin = $this.data('plugin_' + pluginName);

            if (!plugin) {
                $this.data('plugin_' + pluginName, new Plugin(this, options));
            } else {
                if (typeof options === 'string' && typeof plugin[options] === 'function') {
                    plugin[options].apply(plugin, [].slice.call(arg, 1));
                }
            }
        });
    }
}

CS.MD.search = function() {
    var pluginName = 'search';
    var cookie = lgkorUI.cookie;
    var cookieKeyword = lgkorUI.recentlyKeyword

    function Plugin(el, opt) {
        var self = this;
        var defaults = {
            data: {},
            template: {
                autocompleteList: '<li><a href="#">{{keyword}}</a></li>',
                recentlyList: '<li><a href="#">{{keyword}}</a><button type="button" class="btn-delete"><span class="blind">삭제</span></button></li>',
                keywordList: '<li><a href="#">{{keyword}}</a></li>'
            }
        };

        self.$el = $(el);
        self.options = $.extend({}, defaults, opt);
        
        self._initialize();
        self._bindEvent();  
    }

    Plugin.prototype = {
        _initialize: function() {
            var self = this;
            
            self.autoUrl = self.$el.data('autocompleteUrl');
            self.keyword;
            self.isKeyword = self.$el.find('.keyword-box').length ? true : false;

            self.isKeyword && self._setRecently();
            self.isSupporHome = $('.contents').hasClass('support-home');
        },
        _setRecently: function() {
            var self = this;
            var $recentlyKeyword = self.$el.find('.recently-keyword');
            var tmpl = self.options.template,
                keywordCookie = cookie.getCookie('LG_SupportKeyword'),
                arr = [];
            
            $recentlyKeyword.find('ul').empty();

            if (keywordCookie && keywordCookie.length > 0) {
                arr = keywordCookie.split(',');
                if (arr.length) {
                    arr.forEach(function(item) {                        
                        var html = tmpl.recentlyList.replace('{{keyword}}', item.toString());
                        
                        $recentlyKeyword.find('ul').append(html);
                    });
                    $recentlyKeyword.find('ul').show();
                    $recentlyKeyword.find('.no-keyword').hide();
                } else {    
                    $recentlyKeyword.find('ul').hide();
                    $recentlyKeyword.find('.no-keyword').show();
                }
            } else {
                $recentlyKeyword.find('ul').hide();
                $recentlyKeyword.find('.no-keyword').show();
            }            
        },
        setPopularKeyword: function(data) {
            var self = this;
            var $popularKeyword = self.$el.find('.popular-keyword');
            var tmpl = self.options.template,
                arr = data instanceof Array ? data : [],
                html = '';

            $popularKeyword.find('ul').empty();

            if (arr.length) {
                arr.forEach(function(item) {                    
                    html += tmpl.keywordList.replace('{{keyword}}', item);
                });

                $popularKeyword.find('ul').html(html);
                $popularKeyword.find('ul').show();
                $popularKeyword.find('.no-keyword').hide();
            } else {
                $popularKeyword.find('ul').hide();
                $popularKeyword.find('.no-keyword').show();
            }
        },
        reset: function() {
            var self = this;

            self.$el.find('input[type=text]').val('');
            self.$el.find('input[type=text]').trigger('update');
            self.$el.find('.search-more').hide();
            self.$el.find('.search-error').hide();
            self.$el.find('input[type=checkbox]').prop('checked', false);
        },
        // BTOCSITE-15280 고객지원 Main 통합 검색의 자동 완성 기능 추가 요청 
        _setAutoCompleteOnMain: function(result) {
            var self = this;
            var param = result.param;
            var data = result.data;
            var searchedValue = param.search;
            var replaceText = '<span class="search-word">' + searchedValue + '</span>';
            
            var tmpl = self.options.template,
                arr = (data && data.listData instanceof Array) ? data.listData : [];

            if (arr.length) {
                var $list_ul = self.$el.find('.autocomplete-box').find('.keyword-list ul');
                $list_ul.empty();
                
                arr.forEach(function(item, index) {
                    // BTOCSITE-70096 고객지원 > 메인 리뉴얼
                    $list_ul.append(vcui.template(tmpl.autocompleteList, {"input":item.text, "text":vcui.string.replaceAll(item.text, searchedValue, replaceText)}));
                });
                self.$el.find('.autocomplete-box').find('ul').show();
                self.$el.find('.autocomplete-box').find('.no-keyword').hide();
            } else {
                self.$el.find('.autocomplete-box').find('ul').hide();
                self.$el.find('.autocomplete-box').find('.no-keyword').show();
            }
        },
        _setAutoCompleteOnModel: function(param, result) {
            var self = this,
                data = result.data,
                searchedValue = param.keyword; // -> 검색어
                
            var replaceText = '<span class="search-word">' + searchedValue + '</span>';

            var tmpl = self.options.template,
                arr = (data && data.listData instanceof Array) ? data.listData : [];

            if (arr.length) {
                var $list_ul = self.$el.find('.autocomplete-box').find('.keyword-list ul');
                $list_ul.empty();
                
                arr.forEach(function(item, index) {
                    $list_ul.append(vcui.template(tmpl.autocompleteList, {"input":item, "text":vcui.string.replaceAll(item.modelCode, searchedValue, replaceText)}));
                });
                self.$el.find('.autocomplete-box').find('ul').show();
                self.$el.find('.autocomplete-box').find('.no-keyword').hide();
            } else {
                self.$el.find('.autocomplete-box').find('ul').hide();
                self.$el.find('.autocomplete-box').find('.no-keyword').show();
            }
        },
        _setAutoComplete: function(data) {
            var self = this;
            var tmpl = self.options.template,
                arr = data instanceof Array ? data : [];

            self.$el.find('.autocomplete-box').find('ul').remove();

            if (arr.length) {
                var html = vcui.template(tmpl.autocompleteList, {
                    list: arr
                });
                self.$el.find('.autocomplete-box').find('.keyword-list').prepend(html);
                self.$el.find('.autocomplete-box').find('ul').show();
                self.$el.find('.autocomplete-box').find('.no-keyword').hide();
            } else {
                self.$el.find('.autocomplete-box').find('ul').hide();
                self.$el.find('.autocomplete-box').find('.no-keyword').show();
            } 
        },
        _search: function() {
            var self = this;
            var val = self.$el.find('input[type=text]').val().trim();
            // console.log('val', val)
            val = val.replace(/(<([^>]+)>)/ig,""); //BTOCSITE-5089
            val = val.replace(/<\/([^>]+)/ig,""); //BTOCSITE-5089
            // console.log('editted val', val)
            
            if (val.length > 1) {
                if (self.$el.find('.recently-keyword').length) {
                    cookieKeyword.addCookie(val);
                    self._setRecently();
                }
                self.$el.find('.search-error').hide();
            } else {
                self.$el.find('.search-error').show();   
            }

            self.$el.removeClass('on');
            self.$el.trigger('searchafter');

        },
        _bindEvent: function() {
            var self = this;
           
            self.$el.on('click', '.search-layer .btn-delete', function(e) {
                var $box = $(this).closest('li');
                cookie.deleteCookie('LG_SupportKeyword', $box.find('a').text())
                self._setRecently();
            });

            self.$el.on('click', '.search-layer .btn-close', function() {
                self.$el.removeClass('on');
            });

            self.$el.on('click', '.search-layer .autocomplete-box a', function(e) {
                
                // BTOCSITE-15280 고객지원 Main 통합 검색의 자동 완성 기능 추가 요청
                if(self.isSupporHome) {
                    e.preventDefault();
                    var searchVal = $(this).attr('href').replace("#", "");

                    //BTOCSITE-81830 [검색] 고객지원 페이지내 검색어 집계
                    lgkorUI.sendKeyword(searchVal, '3', '4', 'supportSearch');
                    
                    self.$el.find('input[type=text]').val(searchVal);
                    self.$el.removeClass('on');
                    self.$el.trigger('keywordClick');
                }else{
                    self.$el.trigger('autocompleteClick', [this]);
                }
            });

            self.$el.on('click', '.search-layer .keyword-box a', function(e) {
                e.preventDefault();

                var val = $(this).contents()[0].textContent.trim();//BTOCSITE-5089

                if(self.isSupporHome) {
                    //BTOCSITE-81830 [검색] 고객지원 페이지내 검색어 집계
                    lgkorUI.sendKeyword(val, '3', '1', 'supportSearch');
                }
                // BTOCSITE-25756 [검색창] 고객지원 내 일부 검색 창에 [삭제버튼, X] 비노출 현상 수정 요청
                self.$el.find('.ui_input_clearbutton').vcInputClearButton('updateVal', val);
                // self.$el.find('input[type=text]').val(val);
                self.$el.removeClass('on');
                self.$el.trigger('keywordClick');
            });

            self.$el.find('input[type=text]').on('focus', function() {
                self.isKeyword && self.$el.addClass('on');
            }).on('input', function(e) {
                var val = $(this).val().toUpperCase();
                
                if (self.keyword == val) return;

                if (val.length > 1) {
                    // BTOCSITE-15280 고객지원 Main 통합 검색의 자동 완성 기능 추가 요청
                    //var param = {};
                    var param = {};
                    
                    if(self.isSupporHome) {
                        param = {
                            // BTOCSITE-15612 고객지원 Main 검색 자동 완성 수정 요청
                            type: 'support',
                            search: val
                        }; 
                    }else{
                        param = {
                            keyword: val
                        };  
                    }

                    if($(this).hasClass('model-code')) {
                        param = $.extend(param, { categoryNm: "전체",
                        subCategoryNm: "전체",
                        pageCode: $('#pageCode').val(),
                        serviceType: $('#serviceType').val(),
                        page: 1})
                    }

                    self.$el.trigger('autocomplete', [param, self.autoUrl, function(result) {
                        self._setAutoComplete(result.searchList)
                        
                        self.$el.find('.autocomplete-box').show();
                        self.$el.find('.keyword-box').hide();

                        self.$el.addClass('on');
                    }]);

                    // BTOCSITE-15280 고객지원 Main 통합 검색의 자동 완성 기능 추가 요청
                    self.$el.trigger('autocompleteonmain', [param, self.autoUrl, function(result) {
                        //console.log(self);
                        //console.log(self.$el.hasClass('ui_search_model'));
                        if(self.$el.hasClass('ui_search_model')) self._setAutoCompleteOnModel(param,result);
                        else self._setAutoCompleteOnMain(result);
                        
                        self.$el.find('.autocomplete-box').show();
                        self.$el.find('.keyword-box').hide();

                        self.$el.addClass('on');
                    }]);

                    self.$el.find('.search-error').hide();
                } else {
                    self.$el.find('.autocomplete-box').find('ul').empty();
                    self.$el.find('.autocomplete-box').hide();
                    self.$el.find('.keyword-box').show();
                    !self.isKeyword && self.$el.removeClass('on');
                }

                self.keyword = val;
            }).on('keyup', function(e) {
                if (e.keyCode == 13) {
                    e.preventDefault();

                    if(self.isSupporHome) {
                        //BTOCSITE-81830 [검색] 고객지원 페이지내 검색어 집계
                        lgkorUI.sendKeyword(self.keyword, '1', '1', 'supportSearch');
                    }
                    
                    self._search();
                }
            });

            self.$el.find('.btn-search').on('click', function() {
                if(self.isSupporHome) {
                    //BTOCSITE-81830 [검색] 고객지원 페이지내 검색어 집계
                    lgkorUI.sendKeyword(self.keyword, '1', '1', 'supportSearch');
                }

                self._search();
            });

            self.$el.find('.btn-list-all').on('click', function() {
                self._search();
            }); 

            $('body').on('click', function (e) {
                if (!$(e.target).parents('.keyword-search')[0]) {
                    self.$el.removeClass('on');
                }
            });
        }
    };

    CS.MD.plugin(pluginName, Plugin);
}();

CS.MD.calendar = function() {
    var dateUtil = vcui.date;
    var detect = vcui.detect;

    var daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    var dateRegex = /[0-9]{4}.?[0-9]{2}.?[0-9]{2}/;

    var pluginName = 'calendar';

    function Plugin(el, opt) {
        var self = this;
        self.$el = $(el),
        self.el = el;

        var defaults = {
            weekNames: ['일', '월', '화', '수', '목', '금', '토'],
            titleFormat: 'yyyy년 MM월 dd일',
            inputTarget: '', // 날짜를 선택했을 때, 날짜가 들어갈 인풋박스의 셀렉터
            date: new Date(), // 처음에 표시할 기본 날짜
            today: new Date(), // 오늘 날짜
            template: {
                header: '<div class="month-wrap">' + '<button type="button" class="arrow prev"><span class="blind">이전</span></button>' + '<span class="month"></span>' + '<button type="button" class="arrow next"><span class="blind">다음</span></button>' + '</div>',
                button: '<button type="button" class="day {{disabled?" disabled":""}}" title="{{title}}" {{disabled?"disabled":""}}><span>{{day}}</span></button>',
                timeButton: '<button type="button" class="{{disabled?" disabled":""}}" title="{{title}}" {{disabled?"disabled":""}}><span>{{time}}</span></button>'
            },
            caption: '캘린더입니다. 글은 일요일, 월요일, 화요일, 수요일, 목요일, 금요일, 토요일 순으로 나옵니다',
            colWidth: 'calc(100% / 7)', // 셀 너비
            format: 'yyyy.MM.dd',
            paramFormat: 'yyyyMMdd'
        };

        self.options = $.extend({}, defaults, opt);
        
        self._initialize();
    }

    Plugin.prototype = {
        _initialize: function() {
            var self = this,
                arr = self.options.dateArr instanceof Array ? self.options.dateArr : [];


            if (arr.length) {
                for (var i = 0; i < arr.length; i++) {
                    arr.push(vcui.date.parse(arr[i]));
                }
                self.currDate = arr[0];
            } else {
                self.currDate = self.options.today;
            }

            if (self.options.inputTarget) {
                self.$input = $(self.options.inputTarget);
            }

            self.dateArr = arr;

            self._render();
        },
        _render: function _render() {
            var self = this,
                opts = self.options,
                tmpl;

            tmpl = opts.template.header + '<div class="box-table"></div>';

            self._remove();
            self.$calendar = $(tmpl);
            self.$el.empty().append(self.$calendar);

            self.$el.find(self.$calendar).off('.calendar').on('click.calendar', '.arrow', function (e) {
                var $el = $(e.currentTarget),
                    isPrev = $el.hasClass('prev')

                if ($el.hasClass('disabled')) {
                    return;
                }

                self[isPrev ? 'prev' : 'next']();
                self.$calendar.find('.' + (isPrev ? 'prev' : 'next')).focus();
            }).on('click.calendar', '.day:not(.disabled)', function (e) {
                // 날짜 클릭
                e.preventDefault();
                if ($(e.currentTarget).hasClass('disabled')) {
                    return;
                }

                var $this = $(this).closest('td'),
                    data = $this.data(),
                    date = new Date(data.year, data.month -1, data.day),
                    format = dateUtil.format(date, opts.paramFormat || '');

                self.$calendar.find('.choice').removeClass('choice');
                self.activeDate = date;
                $this.addClass('choice');
                $this.closest('tbody').find('td').find('button span.blind').remove();
                $this.find('button').append('<span class="blind">선택됨</span>');
                if (opts.inputTarget) {
                    self.$input.val(format);
                }

                self.$el.trigger('dateselected', [format]);
            })

            self._renderHeader();
            self._renderDate();

            return self;
        },
        /**
         * 달력 그리기
         * @returns {Calendar}
         * @private
         */
        _renderDate: function _renderDate() {
            var self = this,
                opts = self.options,
                date = self._getDateList(self.currDate),
                html = '',
                tmpl = vcui.template(opts.template[opts.type] || opts.template.button),
                isToday = false,
                isSelectDay = false,
                isOtherMonth = false,
                isDisabled = false,
                i, j, y, m, d, week, len, nowd;

            html += '<table class="tb-calendar"><caption>' + opts.caption + '</caption>';
            html += '<colgroup>';
            for (i = 0; i < 7; i++) {
                html += '<col width="' + opts.colWidth + '" />';
            }
            html += '</colgroup><thead>';
            for (i = 0; i < 7; i++) {
                html += '<th class="' + (i === 0 ? ' sun' : i === 6 ? ' ui-calendar-saturday' : '') + '" scope="col">';
                html += opts.weekNames[i];
                html += '</th>';
            }
            html += '</thead><tbody>';
            for (i = 0, len = date.length; i < len; i++) {
                week = date[i];

                html += '<tr>';
                for (j = 0; j < 7; j++) {
                    y = week[j].year, m = week[j].month, d = week[j].day;
                    nowd = new Date(y, m - 1, d);

                    if (self.activeDate) {
                        isSelectDay = self.activeDate.getFullYear() === y && self.activeDate.getMonth() + 1 === m && self.activeDate.getDate() === d;
                    }
                    isToday = opts.today.getFullYear() === y && opts.today.getMonth() + 1 === m && opts.today.getDate() === d;
                    isOtherMonth = self.currDate.getMonth() + 1 != m;
                    isDisabled = !self._compareDate(nowd);

                    html += '<td class="' + (isDisabled ? " disabled" : "");
                    
                    html += (j === 0 ? ' ui-calendar-sunday' : j === 6 ? ' ui-calendar-saturday' : '') + (isToday ? ' ui-calendar-today' : '') + (!isDisabled && isSelectDay ? ' choice' : '');
                    
                    html += '" data-year="' + y + '" data-month="' + m + '" data-day="' + d + '">';

                    if (!isOtherMonth) {
                        html += tmpl({
                            title: dateUtil.format(nowd, opts.titleFormat) + (isToday ? ' 오늘' : '') + (isDisabled ? " 선택할 수 없음" : isSelectDay ? ' 선택일' : ''),
                            isToday: isToday,
                            isOtherMonth: isOtherMonth,
                            isSunday: j === 0,
                            isSaturday: j === 6,
                            day: d,
                            date: nowd,
                            disabled: isDisabled
                        });
                    } else {
                        html += '&nbsp;';
                    }

                    html += '</td>';
                } // for
                html += '</tr>';
            } // for
            html += '</tbody></table>';

            self.$el.find('.box-table').html(html);

            return self;
        },
        /**
         * 헤더에 현재 날짜에 대한 정보 표시
         * @private
         */
        _renderHeader: function _renderHeader() {
            var self = this;

            var currDate = new Date(self.currDate.getTime()),
                minDate = vcui.date.parse(self.dateArr[0]),
                maxDate = vcui.date.parse(self.dateArr[self.dateArr.length - 1]),
                html,
                $second = self.$el.find('.month-wrap');
                isFirst = currDate.getFullYear() === minDate.getFullYear() && currDate.getMonth() === minDate.getMonth(),
                isLast = currDate.getFullYear() === maxDate.getFullYear() && currDate.getMonth() === maxDate.getMonth();
            
            html = currDate.getFullYear() + '<span class="blind">년</span>.' + (currDate.getMonth() + 1)+ '<span class="blind">월</span>';
            $second.find('.month').html(html);
            
            $second.find('.prev').toggleClass('disabled', isFirst).prop('disabled', isFirst).attr('tabindex', isFirst ? '-1' : '0');
            $second.find('.next').toggleClass('disabled', isLast).prop('disabled', isLast).attr('tabindex', isLast ? '-1' : '0');
        },
        _remove: function _remove() {
            var self = this;

            if (self.$calendar) {
                self.$calendar.off();
                self.$calendar.remove();
                self.$calendar = null;
            }

            return self;
        },
        /**
         * 주어진 날짜가 유효한 범위에 있는가 체크
         * @param date
         * @returns {*}
         * @private
         */
        _compareDate: function _compareDate(date) {
            var self = this,
                flag = false;
            if (!(date instanceof Date)) {
                date = dateUtil.parse(date);
            }
            if (!date || isNaN(date)) {
                return null;
            }
            date.setHours(0, 0, 0, 0);

            self.dateArr.forEach(function(item) {
                item = vcui.date.parse(item);

                if (date.getTime() === item.getTime()) {
                    flag = true;
                    return false;
                }
            });

            return flag;
        },
        /**
         * 날짜 데이타 계산
         * @param {Date} date 렌더링할 날짜 데이타 생성
         * @return {Array}
         */
        _getDateList: function _getDateList(date) {
            date.setDate(1);

            var self = this,
                month = date.getMonth() + 1,
                year = date.getFullYear(),
                startOnWeek = date.getDay() + 1,
                last = daysInMonth[date.getMonth()],
                // 마지막날
            prevLast = daysInMonth[date.getMonth() === 0 ? 11 : date.getMonth() - 1],
                // 이전달의 마지막날
            startPrevMonth = prevLast - startOnWeek,
                // 이전달의 시작일
            y = year,
                m = month;

            if (month > 12) {
                month -= 12, year += 1;
            } else {
                if (month == 2 && self._isLeapYear(year)) {
                    last = 29;
                }
            }

            var data = [],
                week = [];

            if (startOnWeek > 0) {
                if (month == 3 && self._isLeapYear(year)) {
                    startPrevMonth += 1;
                }
                if ((m = month - 1) < 1) {
                    m = 12, y = year - 1;
                }
                for (var i = 1; i < startOnWeek; i++) {
                    week.push({ year: y, month: m, day: startPrevMonth + i + 1 }); // ***** +1
                }
                if (week.length > 6) {
                    data.push(week), week = [];
                }
            }

            for (var i = 1; i <= last; i++) {
                week.push({ year: year, month: month, day: i });
                if (week.length > 6) {
                    data.push(week), week = [];
                }
            }

            if (week.length > 0 && week.length < 7) {
                if ((m = month + 1) > 12) {
                    m -= 12, y = year + 1;
                }
                for (var i = week.length, d = 1; i < 7; i++, d++) {
                    week.push({ year: y, month: m, day: d });
                }
            }
            week.length && data.push(week);
            return data;
        },
        update: function update(dateArr) {
            var self = this,
                arr = [];

            self.activeDate = null;
            self.dateArr = dateArr;

            for (var i = 0; i < self.dateArr.length; i++) {
                arr.push(vcui.date.parse(self.dateArr[i]));
            }

            self.currDate = arr[0];

            if (self.options.inputTarget) {
                self.$input.val('');
            }

            self._render();
        },
        reset: function reset() {
            var self = this;

            self.activeDate = null;
            self.currDate = self.options.today;
            self.dateArr = [];

            self.$el.find('.choice').removeClass('choice');

            if (self.options.inputTarget) {
                self.$input.val('');
            }

            self._render();
        },
        /**
         * 이전달
         * @returns {Calendar}
         */
        prev: function prev() {
            var self = this,
                currDate = vcui.date.add(self.currDate, 'M', -1);
            if (self.options.header && self._compareMonth(currDate) !== 0) {
                return this;
            }
            self.currDate = currDate;
            self._renderHeader();
            self._renderDate();

            return this;
        },

        /**
         * 다음달
         * @returns {Calendar}
         */
        next: function next() {
            var self = this,
                currDate = vcui.date.add(self.currDate, 'M', 1);
            if (self.options.header && self._compareMonth(currDate) !== 0) {
                return this;
            }
            self.currDate = currDate;
            self._renderHeader();
            self._renderDate();

            return this;
        },
        /**
         * 윤년 여부
         * @param {Date} date 렌더링할 날짜 데이타 생성
         * @return {boolean} 윤년 여부
         */
        _isLeapYear: function _isLeapYear(year) {
            return year % 4 === 0 && year % 100 !== 0 || year % 400 === 0;
        }
    }

    CS.MD.plugin(pluginName, Plugin);
}();

CS.MD.timeCalendar = function() {
    var dateUtil = vcui.date;
    var detect = vcui.detect;

    var dateRegex = /[0-9]{2}:?[0-9]{2}/;

    var pluginName = 'timeCalendar';

    function Plugin(el, opt) {
        var self = this;
        self.$el = $(el),
        self.el = el;

        var defaults = {
            timeName: ['09', '10', '11', '12', '13', '14', '15', '16', '17'],
            titleFormat: 'hh시 mm분',
            template: {
                button: '<button type="button" class="{{disabled?"disabled":""}}" title="{{title}}" {{disabled?"disabled":""}}><span>{{time}}분</span></button>'
            },
            caption: '시간 캘린더입니다. 글은 9시, 10시, 11시, 12시, 13시, 14시, 15시, 16시, 17시 순으로 나옵니다',
            colWidth: 'calc(85% / 6)', // 셀 너비
            isNight: false, // BTOCSITE-45859 홈페이지 (야간) 출장 예약 서비스 준비
            format: 'hh:mm'
        };

        self.options = $.extend({}, defaults, opt);
        
        self._initialize();
    }

    Plugin.prototype = {
        _initialize: function() {
            var self = this;

            self.timeArr = self.options.timeArr instanceof Array ? self.options.timeArr : [];

            if (self.options.inputTarget) {
                self.$input = $(self.options.inputTarget);
            }

            self._render();
        },
        _render: function _render() {
            var self = this,
                tmpl;

            tmpl = '<div class="box-table"></div>';

            self._remove();
            self.$calendar = $(tmpl);
            
            self.$el.find('>*').remove()
            self.$el.append(self.$calendar);
            
            self.$calendar.off('.timecalendar').on('click.timecalendar', 'button:not(.disabled)', function (e) {
                // 날짜 클릭
                e.preventDefault();
                
                var $this = $(this).closest('td'),
                    data = $this.data(),
                    time = data.hour + '' + data.min;

                self.$calendar.find('.choice').removeClass('choice');
                self.activeTime = time;
                $this.addClass('choice');
                $this.siblings('th').addClass('choice');
                $this.closest('tbody').find('td').find('button span.blind').remove();
                $this.find('button').append('<span class="blind">선택됨</span>');

                if (self.options.inputTarget) self.$input.val(time);

                self.$el.trigger('timeselected', [time]);
            });
            
            self._renderTime();

            return self;
        },
        /**
         * 시간 그리기
         * @returns {Calendar}
         * @private
         */
        _renderTime: function _renderTime() {
            var self = this,
                opts = self.options,
                html = '',
                tmpl = vcui.template(opts.template.button),
                isDisabled = false,
                i, j, time, nowd, hour, min;

            //time = self._getTimeList();

            // BTOCSITE-45859 홈페이지 (야간) 출장 예약 서비스 준비
            if(self.options.isNight){
                self.options.timeName = ['09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19'];
            }

            html += '<table class="tb-timetable"><caption>' + opts.caption + '</caption>';
            html += '<colgroup>';
            for (i = 0; i < 7; i++) {
                if (i == 0) {
                    html += '<col width="' + '15%' + '" />';
                } else {
                    html += '<col width="' + opts.colWidth + '" />';
                }
            }
            html += '</colgroup><tbody>';
            for (i = 0; i < opts.timeName.length; i++) {
                html += '<tr>';

                html += '<th scope="row">'+ opts.timeName[i] +'시</th>';
                for (j = 0; j < 6; j++) {
                    
                    hour = opts.timeName[i];
                    min = j + '0';
 
                    nowd = hour + min;

                    isDisabled = !self._compareDate(nowd);
                    
                    html += '<td class="' + (isDisabled ? "disabled" : "");
                    html += '" data-hour="' + hour + '" data-min="' + min + '">';
                    
                    html += tmpl({
                        title: hour + "시" + min + "분" + (isDisabled ? " 선택할 수 없음" : ""),
                        time: min,
                        disabled: isDisabled
                    });

                    html += '</td>';
                } // for
                html += '</tr>';
            } // for
            html += '</tbody></table>';

            self.$el.find('.box-table').html(html);

            // BTOCSITE-45859 홈페이지 (야간) 출장 예약 서비스 준비
            if(self.options.isNight){  
                self.$el.find('.box-table').parent('.time-wrap').addClass('night');
            }

            return self;
        },
        /**
         * 주어진 날짜가 유효한 범위에 있는가 체크
         * @param date
         * @returns {*}
         * @private
         */
        _compareDate: function _compareDate(time) {
            var self = this,
                flag = false;
            
            if (!time || isNaN(time)) {
                return null;
            }

            self.timeArr.forEach(function(item) {
                if (time == item) {
                    flag = true;
                    return false;
                }
            });

            return flag;
        },
        _remove: function _remove() {
            var self = this;

            if (self.$calendar) {
                self.$calendar.off();
                self.$calendar.remove();
                self.$calendar = null;
            }

            return self;
        },
        update: function update(timeArr) {
            var self = this;

            self.timeArr = timeArr;
            if (self.options.inputTarget) {
                self.$input.val('');
            }

            self._render();
        },
        reset: function reset() {
            var self = this;

            self.timeArr = [];
            self.$el.find('.choice').removeClass('choice');

            if (self.options.inputTarget) {
                self.$input.val('');
            }

            self._render();
        }
    }

    CS.MD.plugin(pluginName, Plugin);
}();


//BTOCSITE-64827 고객지원 > 출장예약 > 모바일 > 날짜/시간 선택 UI 변경
CS.MD.timeCalendarNew = function() {
    var pluginName = 'timeCalendarNew';
    var dateUtil = vcui.date;
    var detect = vcui.detect;
    function Plugin(el, opt) {
        var self = this;
        self.$el = $(el),
        self.el = el;

        
        var defaults = {
            //timeName: ['9', '10', '11', '12', '13', '14', '15', '16', '17'],
            titleFormat: 'hh시 mm분',
            template: {
                button: '<button type="button" title="{{title}}" class="{{evening?"evening":""}}" {{disabled?"disabled":""}}>{{time}}</button>'
            },
            //isNight: true, // BTOCSITE-45859 홈페이지 (야간) 출장 예약 서비스 준비
            format: 'hh:mm'
        };

        self.options = $.extend({}, defaults, opt);
        self._initialize();
    }

    Plugin.prototype = {
        _initialize: function() {
            var self = this;

            self.timeArr = self.options.timeArr instanceof Array ? self.options.timeArr : [];

            if (self.options.inputTarget) {
                self.$input = $(self.options.inputTarget);
            }
            
            self._render();
        },
        _render: function _render() {
            var self = this,
                tmpl;

            tmpl = '<div class="box-cont"></div>';

            //self._remove();
            self.$calendar = $(tmpl);

            //self.$el.find('>*').remove();
            self.$el.find('.box-cont').remove();
            self.$el.append(self.$calendar);

            self.$calendar.off('.timecalendar').on('click.timecalendar', 'button:not(.disabled)', function (e) {
                // 날짜 클릭
                e.preventDefault();
                
                var $this = $(this).closest('li'),
                    data = $this.data(),
                    time = data.hour + '' + data.min;

                self.$calendar.find('.choice').removeClass('choice');
                self.activeTime = time;
                $this.addClass('choice');

                //$this.siblings('li').addClass('choice');
                //$this.closest('tbody').find('td').find('button span.blind').remove();
                //$this.find('button').append('<span class="blind">선택됨</span>');

                if (self.options.inputTarget) self.$input.val(time);

                self.$el.trigger('timeselected', [time]);
            });

            self._renderTime();

            return self;
        },
        _renderTime: function _renderTime() {
            var self = this;

            var self = this,
                opts = self.options,
                html = '',
                tmpl = vcui.template(opts.template.button),
                isDisabled = false,
                isEvening = false,
                //timeArr = self.timeArr,
                i, j, m, n, time, nowd, hour, min;

            // BTOCSITE-45859 홈페이지 (야간) 출장 예약 서비스 준비
            // if(self.options.isNight){
            //     self.options.timeName = ['9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
            // }
            

            var am = [];
            var pm = [];
            am = vcui.array.filter(self.timeArr, function(item, index) {
                if(parseInt(item) < 1200) {
                    return item;
                }
            });
            pm = vcui.array.filter(self.timeArr, function(item, index) {
                if(parseInt(item) >= 1200) {
                    return item;
                }
            });
        
            var amFirst = am[0];
            var amLast = am[am.length - 1];

            var pmFirst = pm[0];
            var pmLast = pm[pm.length - 1];

            for (i = 0; i < self.timeArr.length; i++) {
                
                hour = self.timeArr[i].substr(0, 2).toString();
                min = self.timeArr[i].substr(2, 2).toString();
                isDisabled = false;
                isEvening = parseInt(self.timeArr[i]) >= 1800 ? true : false; // 18, 19, 20, 21, 22, 23시

                //오전, 오후
                if(parseInt(self.timeArr[i]) < 1200 || parseInt(self.timeArr[i]) >= 1200) {
                    //오전
                    if(parseInt(self.timeArr[i]) == amFirst) {
                        html += '<dl>';
                        html += '<dt>오전</dt>';
                        html += '<dd>';
                        html += '<ul>';
                    }
                    //오후
                    if(parseInt(self.timeArr[i]) == pmFirst) {
                        html += '<dl>';
                        html += '<dt>오후</dt>';
                        html += '<dd>';
                        html += '<ul>';
                    }
                }

                html += '<li data-hour="' + hour + '' + '" data-min="' + min + '">';
                html += tmpl({
                    title: hour + "시" + min + "분" + (isDisabled ? " 선택할 수 없음" : ""),
                    time: hour + ':' + min,
                    disabled: isDisabled,
                    evening: isEvening
                });
                html += '</li>';

                //오전, 오후
                if(parseInt(self.timeArr[i]) < 1200 || parseInt(self.timeArr[i]) >= 1200) {
                    if(parseInt(self.timeArr[i]) == amLast || parseInt(self.timeArr[i]) == pmLast) {
                        html += '</ul>';
                        html += '</dd>';
                        html += '</dl>';
                    }
                }
            }
            self.$el.find('.box-cont').html(html);
        },
        _remove: function _remove() {
            var self = this;

            if (self.$calendar) {
                self.$calendar.off();
                self.$calendar.remove();
                self.$calendar = null;
            }

            return self;
        },
        update: function update(timeArr) {
            var self = this;

            self.timeArr = timeArr;
            if (self.options.inputTarget) {
                self.$input.val('');
            }

            self._render();
        },
        reset: function reset() {
            var self = this;

            self.timeArr = [];
            self.$el.find('.choice').removeClass('choice');

            if (self.options.inputTarget) {
                self.$input.val('');
            }

            self._render();
        }
    }

    CS.MD.plugin(pluginName, Plugin);
}();

CS.MD.pagination = function() {
    var pluginName = 'pagination';

    function Plugin(el, opt) {
        var self = this,
            el = self.el = el,
            $el = self.$el = $(el);

        var defaults = {
            page: 1,
            totalCount: 1,
            pageCount:10,
            pageView: 10, //BTOCSITE-9921 변경
            prevClass: 'prev',
            nextClass: 'next',
            firstClass: 'first', //BTOCSITE-9921 추가
            lastClass: 'last', //BTOCSITE-9921 추가
            disabledClass: 'disabled',
            lastView: false
        };

        self.options = $.extend({}, defaults, self.$el.data(), opt);

        function _initialize() {
            $el.attr("role","navigation");
            $el.attr("aria-label","Pagination");
            //BTOCSITE-9921 공통 페이지네이션 마크업 생성
            $el.append('<a href="#" class="first"><span class="blind">맨처음 페이지 보기</span></a><a href="#" class="prev"><span class="blind">이전 페이지 보기</span></a><span class="page_num"></span><a href="#" class="next"><span class="blind">다음 페이지 보기</span></a><a href="#" class="last"><span class="blind">맨마지막 페이지 보기</span></a>');

            self.$pageList = $el.find('.page_num');
            self.$prev = $el.find('.' + self.options.prevClass);
            self.$next = $el.find('.' + self.options.nextClass);
            //BTOCSITE-9921 first, last 클래스 추가 - S
            self.$first = $el.find('.' + self.options.firstClass);
            self.$last = $el.find('.' + self.options.lastClass);
            //BTOCSITE-9921 first, last 클래스 추가 - E

            self.pageTotal = Math.floor((self.options.totalCount - 1)  / self.options.pageCount + 1);

            self._setEvent();
            self._update();
        }

        _initialize();
    }

    Plugin.prototype = {
        _update: function(page) {
            var self = this,
                page = page || self.options.page,
                pageView = self.options.pageView,
                pageTotal = self.pageTotal,
                html = '';
                pageChkType = document.getElementsByClassName("video-guide"); //BTOCSITE-9921 추가 - 페이지 예외처리 타입(고객지원 > 동영상 가이드)


            // BTOCSITE-9921 추가 - pageView 값 분리 - S
            // mobile 일 경우, pagination 5개, pc 10개 노출 
            if(vcui.detect.isMobileDevice){
                pageView = 5;
            } else if ((!!pageChkType && pageChkType.length != 0) && !vcui.detect.isMobileDevice) {
                pageView = 5;
            } else {
                pageView = 10;
            }            
            // BTOCSITE-9921 추가 - pageView 값 분리 - E

            var startPage = parseInt((page - 1) / pageView) * pageView + 1; 
            var endPage = startPage + pageView - 1;
            if (endPage > pageTotal) {
                endPage = pageTotal;
            }

            for (var i = startPage; i <= endPage; i++) {
                if (self.options.page == i) {
                    html += '<strong><span class="blind">현재 페이지</span>' + i + '</strong>';
                } else {
                    html += '<a href="#" data-page="' + i + '" title="' + i + '페이지 보기">' + i + '</a>';
                }
            }

            // BTOCSITE-9921 공통 페이지네이션 수정 '...' 케이스 제거 - S
            // if (self.options.lastView && (startPage + pageView <= pageTotal)) {
            //     html += '<span class="dot">...</span>';
            //     html += '<a href="#" data-page="' + pageTotal + '" title="' + pageTotal + '페이지 보기">' + pageTotal + '</a>';
            // }
            // BTOCSITE-9921 공통 페이지네이션 수정 '...' 케이스 제거 - E

            if (page > pageView) {
                self.$prev
                    .attr('aria-disabled', false)
                    .removeClass(self.options.disabledClass)
                    .data('page', startPage - 1);
            } else {
                self.$prev
                    .attr('aria-disabled', true)
                    .addClass(self.options.disabledClass)
                    .data('page', '');
            }

            if (startPage + pageView <= pageTotal) {
                self.$next
                    .attr('aria-disabled', false)
                    .removeClass(self.options.disabledClass)
                    .data('page', endPage + 1);
            } else {
                self.$next
                    .attr('aria-disabled', true)
                    .addClass(self.options.disabledClass)
                    .data('page', '');
            }
            //BTOCSITE-9921 first,last 버튼 추가 - s
            if (page > 1) {
                self.$first
                    .attr('aria-disabled', false)
                    .removeClass(self.options.disabledClass)
                    .data('page', '1');
            } else {
                self.$first
                    .attr('aria-disabled', true)
                    .addClass(self.options.disabledClass)
                    .data('page', '');
            }

            if (page < pageTotal) {
                self.$last
                    .attr('aria-disabled', false)
                    .removeClass(self.options.disabledClass)
                    .data('page', pageTotal);
            } else {
                self.$last
                    .attr('aria-disabled', true)
                    .addClass(self.options.disabledClass)
                    .data('page', '');
            }
            //BTOCSITE-9921 first,last 버튼 추가 - e
            
            self.$el.data('pageTotal', pageTotal);
            self.$el.data('totalCount', self.options.totalCount);
            self.$el.data('page', page);

            self.$pageList.html(html);
        },
        update: function(data) {
            var self = this;

            self.options.page = data.page;
            self.options.totalCount = data.totalCount;
            self.pageTotal = Math.floor(self.options.totalCount == 0 ? 1 : (self.options.totalCount - 1)  / self.options.pageCount + 1);

            self._update();
        },
        _setEvent: function() {
            var self = this;
            
            self.$el.on('click', 'a', function(e) {
                e.preventDefault();
                
                var $this = $(this),
                    page = $this.data('page');

                if ($this.hasClass(self.options.disabledClass) || $this.attr('aria-disabled') == true) return;

                //BTOCSITE-9921 구조 변경 - S   
                // 분기 구문 제거 (공통 페이지네이션 변경으로 불필요해짐 )
                // if (self.options.lastView && ($this.hasClass(self.options.prevClass) || $this.hasClass(self.options.nextClass))) {
                //     self._update(page);
                // } else {    
                //     self.$el.trigger({
                //         type: 'pageClick',
                //         page: page
                //     });
                // }

                self.$el.trigger({
                    type: 'pageClick',
                    page: page
                });
                //BTOCSITE-9921 구조 변경 - E
            });
        }
    }

    CS.MD.plugin(pluginName, Plugin);
}();

var AuthManager = function() {
    var SENDTEXT = '인증번호 발송';
    var RESENDTEXT = '인증번호 재발송';
    var COMPLETETEXT = '휴대전화 인증 완료';
    
    function AuthManager(options) {
        var self = this;
        var defaults = {
            elem: {
                form: '',
                popup: '',
                name: '',
                phone: '',
                number: ''
            },
            // },
            // target: {
            //     name: '',
            //     phone: ''
            // },
            register: {},
            pass: true
        };

        self.options = options = $.extend({}, defaults, options);
        self.nameName = $(options.elem.name)[0].name;
        self.phoneName = $(options.elem.phone)[0].name;
        self.numberName = $(options.elem.number)[0].name;
        self.popFlag = options.elem.popup ? true : false;

        self.smsUrl = self.popFlag ? $(options.elem.popup).data('smsUrl') : $(options.elem.form).data('smsUrl');
        self.authUrl = self.popFlag ? $(options.elem.popup).data('authUrl') : $(options.elem.form).data('authUrl');

        var register = options.register || {};

        self.validation = new vcui.ui.CsValidation(self.popFlag ? options.elem.popup : options.elem.form, {
            register: register
        });

        $(options.elem.popup).on('modalhide', function(){
            $(this).find('.btn-send').html(SENDTEXT);
            $(options.elem.number).attr('disabled', 'disabled').val('');

            if( options.target ) {
                $(options.elem.name).val('');
                $(options.elem.phone).val('');
            }
            self.validation.reset();
        });

    }

    AuthManager.prototype = {
        send: function(el, completeCallback) {
            var self = this;
            var elem = self.options.elem,
                result = self.validation.validate([self.nameName, self.phoneName]),
                data, url;

            if (result.success) {
                url = self.smsUrl;
                data = self.validation.getValues([self.nameName, self.phoneName]);

                lgkorUI.requestAjaxDataPost(url, data, function(result) {
                    var resultData = result.data;

                    if (resultData.resultFlag == 'Y') {
                        $(el).html(RESENDTEXT);
                        $(elem.number).prop('disabled', false);
                    }

                    lgkorUI.alert("", {
                        title: resultData.resultMessage
                    }, el);

                    // BTOCSITE-18987 가전세척 예약 페이지에 인증 form 형태 달라 확장 시킵니다
                    completeCallback && completeCallback(result);
                });
            }
        },
        open: function(completeCallback) {
            var self = this;
            var elem = self.options.elem;

            completeCallback && completeCallback();

            $(elem.popup).vcModal();
        },
        confirm: function(el, callback) {
            var self = this;
            var $button = $(el),
                elem = self.options.elem,
                target = self.options.target,
                result = self.validation.validate(),
                url, data, success = false;

            if (result.success == true) {
                url = self.authUrl;
                data = self.validation.getValues([self.nameName, self.phoneName, self.numberName]);

                if ($(elem.number).prop('disabled')) {
                    lgkorUI.alert("", {title: '인증 번호 발송 버튼을 선택해주세요.'}, el);
                    return false;
                }

                lgkorUI.showLoading();
                lgkorUI.requestAjaxDataPost(url, data, function(result) {
                    var resultData = result.data;

                    if (resultData.resultFlag == 'Y') {
                        success = true;

                        if (target) {
                            $button.prop('disabled', true);
                            $button.html(COMPLETETEXT);
                            $(target.name).val(data.authName);
                            $(target.phone).val(data.authPhoneNo);
                            $(elem.popup).vcModal('hide');
                        }

                        if (resultData.resultMessage) {
                            lgkorUI.alert("", {
                                //title: '휴대전화 인증이 완료되었습니다.',
                                title: resultData.resultMessage,
                                ok: function(el) {

                                    // BTOCSITE-16745 정기 취약성 점검 개선조치 요청
                                    $('#certiNum').val( data.authNo );

                                    if (resultData.url) {
                                        $(self.options.elem.form).attr('action', resultData.url);
                                        $(self.options.elem.form).submit();
                                    } else {
                                        $(el).vcModal('hide');
                                        callback && callback(success, result);
                                    }
                                }
                            }, el);
                        } else if (resultData.url) {
                            callback && callback(success, result);

                            $(self.options.elem.form).attr('action', resultData.url);
                            $(self.options.elem.form).submit();
                        } else {
                            callback && callback(success, result);
                        }
                    } else {
                        success = false;
                        
                        lgkorUI.alert("", {
                            title: resultData.resultMessage,
                            ok: function(el) {
                                if (resultData.url) {
                                    location.href = resultData.url;
                                } else {
                                    $(el).vcModal('hide');
                                    callback && callback(success, result);
                                }
                            }
                        }, el);
                    }

                    lgkorUI.hideLoading();
                });
            }
        },
        getValidation: function () { // BTOCSITE-18987 가전세척 예약 페이지에 인증 form 형태 달라 추가
            var self = this;
            return self.validation;
        }
    };

    return AuthManager;
}();

// 휴대폰 유효성 검사
function validatePhone(value){
    var _pattern = new RegExp(/^(010|016|011|017|018|019)\d{3,4}\d{4}$/);
            
    if( _pattern.test(value) == true) {
        var _length = value.length;
        var firstVal = value.substr(0,3);
        var num4th = value.substr(3,1);
        var num3 = value.substr(3,3);
        var num4 = value.substr(3,4);

        function validateNum10(){
            if( 200<= num3 && num3 <= 899) {
                return true;
            } else {
                return false;
            }
        }
        function rangeFlag(minNum, maxNum){
            if( minNum <= num4 && num4 <= maxNum)  {
                return true;
            } else {
                return false;
            }
        }

        switch(firstVal){
            case "010":
                if( num4th == 0 || num4th == 1) {
                    return false;
                }

                if( value.length != 11) {
                    return false;
                }
            break;
            case "011":
                if( _length == 10) {
                    return validateNum10();
                }
                if( _length == 11) {
                    //BTOCSITE-1613: 11자리일때 중간4자리 유효범위 수정 9500 -> 9000
                    if(rangeFlag(9000, 9999) || rangeFlag(1700, 1799)) {
                        return true;
                    } else {
                        return false;
                    }
                }
            break;
            case "016":case "019":
                if( _length == 10) {
                    return validateNum10();
                }
                if( _length == 11) {
                    return rangeFlag(9000, 9999);
                }
            break;
            case "017": case "018":
                if( _length == 10) {
                    return validateNum10();
                } else {
                    return false;
                }
            break;
        }
    } else {
        return false;
    }
}

(function($){
    vcui.require(['support/common/quickMenu.min'], function() {
        $('#quickMenu').vcQuickMenu();
    });

    function commonInit(){
        //input type number 숫자키패드
        $('input[type="number"]').attr('inputmode', 'numeric');
        //$('input[type="number"]').attr('oninput', 'this.value = this.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1")');
        
        $('[data-format=koreng]').on('input', function() {
            var $this = $(this),
                value = $this.val();
            
            //var regex = /(^[^가-힣ㄱ-ㅎㅏ-ㅣㄱ-ㅎ가-힣ㅏ-ㅣㆍ ᆢa-zA-Z])|[^가-힣ㄱ-ㅎㅏ-ㅣㄱ-ㅎ가-힣ㅏ-ㅣㆍ ᆢa-zA-Z]|([^가-힣ㄱ-ㅎㅏ-ㅣㄱ-ㅎ가-힣ㅏ-ㅣㆍ ᆢa-zA-Z]$)/g;
            var regex = /[0-9]/g;
            
            if (regex.test(value)) {
                $this.val(value.replace(regex, ''));
                return;
            }
        });

        $('[data-format=email]').on('input', function() {
            var $this = $(this),
                value = $this.val();
            
            var regex = /[가-힣ㄱ-ㅎㅏ-ㅣㆍ ᆢ\s]/g;
            
            if (regex.test(value)) {
                $this.val(value.replace(regex, ''));
                return;
            }
        });

        $('[data-format=alnum]').on('input', function() {
            var $this = $(this),
                value = $this.val();
            
            var regex = /[^a-zA-Z0-9]/g;
            
            if (regex.test(value)) {
                $this.val(value.replace(regex, ''));
                return;
            }
        });


        if( $('#surveyPopup').length) {
            // BTOCSITE-31593 한국 홈페이지 콘텐츠 서베이 양식 변경 요청 건
            var noValidate =  $('#surveyPopup').attr('data-no-validate');

            if(!!noValidate){
                vcui.require(['ui/selectbox', 'ui/satisfactionNoValidate']); // 동영상, 제품문제해결, 사용설명서, 드라이브S/W 적용
            }else{
               vcui.require(['ui/selectbox', 'ui/satisfactionModal']); //센터찾기
            }
        }

        if ($('.ui_common_scroll').length && !vcui.detect.isMobileDevice) {
            $('.ui_common_scroll').mCustomScrollbar();
        }

        $(document).on('input', 'input[type="text"]', function(){
            if (this.maxLength > 0 && this.value.length > this.maxLength){
                this.value = this.value.slice(0, this.maxLength);
            }  
        });

        $(document).on('input', 'input[type="number"]', function(){
            if (this.maxLength > 0 && this.value.length > this.maxLength){
                this.value = this.value.slice(0, this.maxLength);
            }  
        });

        $(document).on('focus', 'input[type="number"]', function(e){
            $(this).on('mousewheel',function(e){
                e.preventDefault();
            });
        });

        $(document).on('keydown', 'input[type="number"]', function(e){
            if( e.keyCode == 189 || e.keyCode == 187 || e.keyCode == 107 || e.keyCode == 109 || e.keyCode == 110 || e.keyCode == 190) {
                e.preventDefault();
            }
        });

        $(document).on('click', '.btn-add-pd', function(e){
            if( !lgkorUI.isLogin ) {
                var _url = $(this).attr('href');
                e.preventDefault();
                
                lgkorUI.confirm('로그인을 하셔야 이용하실 수 있습니다. <br>로그인 하시겠습니까?',{
                    typeClass:'type2',
                    title:'',
                    okBtnName: '네',
                    cancelBtnName: '아니요',
                    ok: function() {
                        location.href = _url;
                    },
                    cancel: function() {
                        
                    }
                });
            }
        });

        $(document).on('keyup', 'input[type="number"]', function(e){
            var $this = $(this);
            var v = $this.val();

            if( e.keyCode != 8 && e.keyCode != 46) {
                if( v != null && v != "") {
                    $this.data('oldValue', v);
                }
            } else {
                $this.data('oldValue', v);
            }
            
        });

        $(document).on('blur', 'input[type="number"]', function(e){
            var $this = $(this);
            var v = $this.val();
            var oldVal = $this.data('oldValue');
            
            if( v == null || v == "") {
                $this.val(oldVal);
            }
        });

        $(document).on('change', '.agree-wrap input:checkbox', function(){
            var $this = $(this);
            var $wrap = $this.closest('.agree-wrap');

            if ($wrap.find('input:checkbox').filter(':checked').length == $wrap.find('input:checkbox').length) {
                var $this = $(this);
                var $curSection = $this.closest('.section').nextAll('.section:visible').eq(0);
                var headerHeight = window.innerWidth < 768 ? $('.header-wrap').height() : 0; // BTOCSITE-21157 고객지원 메뉴 - 포커스 이동 위치 수정
                
                lgkorUI.scrollTo($curSection, $('.prod-selected-wrap').outerHeight() + headerHeight); // BTOCSITE-21157 고객지원 메뉴 - 포커스 이동 위치 수정
            }
        });
        
        $(document).on('ajaxComplete', function() {
            $('img').not('[data-pc-src], #modelNamePopup img').on('error', function() {
                lgkorUI.addImgErrorEvent(this);
            });
            $('#modelNamePopup img').on('error', function() {
                lgkorUI.addModelNameImgErrorEvent(this);
            });
        });

        $('.agree-wrap .agree-cont-box').attr('tabindex', 0);

        if ($('.pay-warranty').length) {
            // BTOCSITE-17965 PC국제보증서비스 페이지 게재 요청
            vcui.require(['ui/tab'], function() {
        
                $('.ui_tab-notice').on('tabchange', function(e, info) {
                    var $this = $(this);
                    var index = info.selectedIndex;
        
                    switch(index) {
                        case 0:
                            lgkorUI.setAcecounter('www.lge.co.kr/acecount/ratesInfo1.do', '/acecount/ratesInfo1m.do');
                            break;
                        case 1:
                            lgkorUI.setAcecounter('www.lge.co.kr/acecount/ratesInfo3.do', '/acecount/ratesInfo3m.do');
                            break;
                        case 2:
                            lgkorUI.setAcecounter('www.lge.co.kr/acecount/ratesInfo4.do', '/acecount/ratesInfo4m.do');
                            break;
                        case 3:
                            $this.vcTab("select", 0);
                            location.href = '/lg-international-warranty-service'; // 국제보증서비스
                            break;
                    }
                });
            });
        }
        
    }

    document.addEventListener('DOMContentLoaded', commonInit);

    $('[data-control="modal"]').each(function() {
        var target = $(this).data('href') || $(this).attr('href');
        
        $(target).on('modalshow', function(e, modal) {
            $(target).removeAttr('style');
        });
    });
})(jQuery);

if (!vcui.detect.isMobileDevice) {
    var _AceGID=(function(){var Inf=['gtp20.acecounter.com','8080','AH5A40639666759','AW','0','NaPm,Ncisy','ALL','0']; var _CI=(!_AceGID)?[]:_AceGID.val;var _N=0;var _T=new Image(0,0);if(_CI.join('.').indexOf(Inf[3])<0){ _T.src ="https://"+ Inf[0] +'/?cookie'; _CI.push(Inf);  _N=_CI.length; } return {o: _N,val:_CI}; })();
    var _AceCounter=(function(){var G=_AceGID;var _sc=document.createElement('script');var _sm=document.getElementsByTagName('script')[0];if(G.o!=0){var _A=G.val[G.o-1];var _G=(_A[0]).substr(0,_A[0].indexOf('.'));var _C=(_A[7]!='0')?(_A[2]):_A[3];var _U=(_A[5]).replace(/\,/g,'_');_sc.src='https:'+'//cr.acecounter.com/Web/AceCounter_'+_C+'.js?gc='+_A[2]+'&py='+_A[4]+'&gd='+_G+'&gp='+_A[1]+'&up='+_U+'&rd='+(new Date().getTime());_sm.parentNode.insertBefore(_sc,_sm);return _sc.src;}})();
} else {
    var _AceGID=(function(){var Inf=['co.kr','www.lgservice.co.kr,lgservice.co.kr,m.lgservice.co.kr,lge.co.kr,m.lge.co.kr,www.lge.co.kr','AZ3A66760','AM','0','NaPm,Ncisy','ALL','0']; var _CI=(!_AceGID)?[]:_AceGID.val;var _N=0;if(_CI.join('.').indexOf(Inf[3])<0){ _CI.push(Inf);  _N=_CI.length; } return {o: _N,val:_CI}; })();
    var _AceCounter=(function(){var G=_AceGID;var _sc=document.createElement('script');var _sm=document.getElementsByTagName('script')[0];if(G.o!=0){var _A=G.val[G.o-1];var _G=(_A[0]).substr(0,_A[0].indexOf('.'));var _C=(_A[7]!='0')?(_A[2]):_A[3];var _U=(_A[5]).replace(/\,/g,'_');_sc.src='https:'+'//cr.acecounter.com/Mobile/AceCounter_'+_C+'.js?gc='+_A[2]+'&py='+_A[1]+'&up='+_U+'&rd='+(new Date().getTime());_sm.parentNode.insertBefore(_sc,_sm);return _sc.src;}})();    
}
// BTOCSITE-1198 챗봇 상담 팝업
$(document).on('click', '.chat-customer .btn-target-link', function(e){
    var target = this.getAttribute('href'),
        popupWidth = parseInt(this.getAttribute('data-width')),
        popupHeight = parseInt(this.getAttribute('data-height')),
        screenWidth = parseInt(screen.width),
        screenHeight = parseInt(screen.height),
        intLeft = Math.floor((screenWidth - popupWidth) / 2),
        intTop = Math.floor((screenHeight - popupHeight) / 2);

    if (intLeft < 0) intLeft = 0;
    if (intTop < 0) intTop = 0;

    e.preventDefault();

    // BTOCSITE-23627 [긴급] 챗봇 상담 메뉴 내 상담챗과 로그인 연동
    // BTOCSITE-25180 [앱-상담챗] 챗봇 > 상담챗 버튼 클릭 시 브라우저로 열리는 오류 수정요청
    lgkorUI.getChatPinCodeAsUrl(target, popupWidth, popupHeight, intLeft, intTop);
})



//제품군 선택
$(document).ready(function(){
    // $('.prod-selected-box').each(function(){
    //     var prodAjaxUrl = $(this).data("ajax-url");
        
    //     lgkorUI.requestAjaxDataPost(prodAjaxUrl, {}, function (result) {
    //         var superCate = result.categoryList.superCategoryName;
    //         var cate = result.categoryList.categoryName;
    //         var cateCnt = result.categoryList.length;
    //         //console.log(result.categoryList);

    //     });
    // });


    //출장예약
    //고장증상
    function topicSet(){

        $('.topic-wrap').each(function(){
            var TopicTarget = $(this);
            var topicAjaxUrl = TopicTarget.data("ajax");
            //console.log(topicAjaxUrl);
            lgkorUI.requestAjaxDataPost(topicAjaxUrl, {}, function (result) {
                if(result.data.topicList) {
                    var topicData = result.data.topicList;
                    var topicCnt = topicData.length;
                    var subTopicData = result.data.subTopicList;
                    var subTopicCnt = subTopicData.length;
                    var topicRadio = "";
                    
                    var radioName = TopicTarget.data("radio-name");
                    for(var i=0;i<topicCnt;i++){
                        if (topicCnt == 0) {
                            topicRadio += '<li>'+
                            '<span class="rdo-wrap btn-type4">'+
                                '<input type="radio" name="'+radioName+'" id="'+radioName+i+'" value="'+topicData[i].value+'" data-group="'+topicData[i].group+'" data-error-msg="정확한 제품증상을 선택해주세요." data-required="true" required>'+
                                '<label for="'+radioName+i+'"><span>'+topicData[i].name+'</span></label>'+
                            '</span>'+
                        '</li>';
                        }
                        else {
                            topicRadio += '<li>'+
                            '<span class="rdo-wrap btn-type4">'+
                                '<input type="radio" name="'+radioName+'" id="'+radioName+i+'" value="'+topicData[i].value+'" data-group="'+topicData[i].group+'">'+
                                '<label for="'+radioName+i+'"><span>'+topicData[i].name+'</span></label>'+
                            '</span>'+
                        '</li>';
                        }
                    }
                    
                    TopicTarget.find('.rdo-list').empty().html(topicRadio);
                
                    var rdo = TopicTarget.find('input[type="radio"]');
                    
                    rdo.on('click', function(){
                        var subTopicOption = "";
                        var topicgroupGrp = $(this).data('group');
                        var topicWrap = $(this).parents(TopicTarget);
                        var selectWrap = topicWrap.find('#stopic01 select');
                        topicWrap.find('.sub-topic').show();
            
                        for(var i=0;i<subTopicCnt;i++){
                            if(topicgroupGrp == subTopicData[i].group) subTopicOption += '<option value="'+subTopicData[i].value+'">'+subTopicData[i].name+'</option>';
                        }
                        //console.log(subTopicOption);
                        selectWrap.empty().html('<option value="all" class="placeholder">증상을 선택해 주세요</option>' + subTopicOption);
                        selectWrap.vcSelectbox('update');
                        //optionPlaceholderDpn('#stopic01');//2023.05.16 추가
                        topicWrap.find('#stopic01 .ui-select-button').trigger('click');
                    });

                    TopicTarget.find('#stopic01 select').on('change', function(){
                        var optionCk = $(this).find('option:selected').val();
                        
                        if(optionCk !== "all") {
                            //console.log(optionCk);
                            $(this).parents('.topic-wrap').find('#stopic02').show();
                            $(this).parents('.topic-wrap').find('#stopic02 .ui-select-button').trigger('click');
                        }
                
                    });

                    TopicTarget.find('#stopic02 select').on('change', function(){
                        $(this).parents('.section').find('.banner-wrap').show();
                    });
                }
            });
        });
        
    }


    //topicSet();

    //내보유상품
    function myProductGroup(){
        var target = $('.product-group');
        var itemCnt = target.find('ul li').length;        
        
        if(itemCnt > 10) {
            //target.find('.btn-group .btn').removeClass('close').addClass('more');
            target.find('ul li:nth-child(n+11)').hide();
            target.find('.btn-group .btn').show();
        }
        else {
            target.find('.btn-group .btn').hide();
        }

        target.find('.btn-group .btn').on('click',function(){
        //lgkorUI.showLoading(); 
        if($(this).hasClass('more') && itemCnt > 10){
                target.find('ul li:nth-child(n+11)').show();
                target.find('.btn-group .btn').removeClass('more').addClass('close').find('span').text('접기');
        }
        else if($(this).hasClass('close') && itemCnt > 10){
                target.find('ul li:nth-child(n+11)').hide();
                target.find('.btn-group .btn').removeClass('close').addClass('more').find('span').text('더보기');
            }
        
        });
    }

    myProductGroup();
        
    $('#prdGrp01 select').on('change', function(){
        var superCate = $(this).find('option:selected').data('category-name');
        if(superCate !== "all") {
            // $('#prdGrp02').show();
            // $('#prdGrp02 .ui-select-button').trigger('click');
            //선택된 상품 표시
            //$('.goods-selected-wrap.prodList').show();
            $('.goods-selected-wrap.prodList .product li').eq(0).text(superCate);
            $('.goods-selected-wrap.prodList .product li').eq(1).text("");
        }
    });

    $('#prdGrp02 select').on('change', function(){
        var superCate = $(this).find('option:selected').val();
        
        if(superCate !== "all") {
            //선택된 상품 표시
            $('.goods-selected-wrap.prodList .product li').eq(1).text(superCate);
            $('.goods-selected-wrap.prodList').fadeIn(500);
            $('.topic-notice').hide();
            $('.topic-wrap .topics').show();
            $(this).closest('.section').hide();
        }
    });

    $('.btn-cal').on('click', function(e){
        e.preventDefault();
        $('#stepDateBefore').hide();
        $('#stepDate .section').addClass('open');
        //$('#stepDate').addClass('active').show();
        //$('.date-wrap').calendar('update', ['20220926', '20220927']);
    });


    
    // $('.btn-confirm').on('click', function(e){
    //     e.preventDefault();
    //     $('.step-btn-wrap').hide();
    // });


    //출장예약 아코디언 영역
    $('.accord-wrap').each(function(){

        $(this).find('.tit-wrap .accord-btn').on('click', function(e){
            e.preventDefault();
            e.stopPropagation();
            $(this).closest('.accord-wrap').toggleClass('open');
            //$(this).closest('.accord-wrap').find('.section-box').slideToggle("fast");
            // BTOCSITE-71268 접근성
            ($(this).closest('.accord-wrap').hasClass('open'))?
                $(this).attr('aria-expanded', true)
                : $(this).attr('aria-expanded', false);
        });

    });

    //제품목록 없을 때 탭이 사라지게 하는 효과
    // $('.tabs-wrap-new a.hide').on('click',function(){
    //     var target = $(this).attr('href');
    //     $(target).fadeIn(500);
    //     //$('.type-step').slideUp(1000);
    //     $(this).parents('.tabs-wrap-new').animate({
    //         height: "toggle",
    //         marginBottom: 0
    //     }, 500);
    // });

    


    //출장서비스
    $('.contents.service-engineer').find('.my-product-wrap li').each(function(){
        $(this).find('a').on('click',function(){
            $('#stepInput').addClass('active');
            $('#ownProductBar').addClass('active');
            // $('.tabs-wrap-new').not('.pop-conts .tabs-wrap-new').addClass("dpn");
        });
    });

    // $('.goods-selected-wrap .btn.reset').on('click',function(){
    //     //location.reload();
    //     $(this).closest('.contents').find('.tabs-wrap-new').removeClass('dpn');
    // });

    $('.contents.service-engineer').find('.ui_tab li').each(function(){
        $(this).find('a').on('click',function(){

            if($(this).hasClass('selected') == false) {
                $('#stepInput').removeClass('active');
            }
            else if($(this).hasClass('selected') == true) {
                $('#stepInput').addClass('active');
            }
        });
    });

    $('.contents.service-engineer').find('#prdGrp02').on('change', function(){
        $('#stepInput').addClass('active');
        //$('.tabs-wrap-new').addClass("dpn");
    });

    //출장서비스 보유제품이 없으면
    if($('html').data('product') == "N") {
        $('.tabs-wrap-new li').not('.pop-conts .tabs-wrap-new li').removeClass("on");
        $('.tabs-wrap-new li.noProduct').addClass("on");
        // $('.tabs-wrap-new').not('.pop-conts .tabs-wrap-new').addClass("dpn");
        

        $('.my-product-wrap.type-list').hide();
        $('.step-contnet').hide();
        $('.step-contnet.noProduct #stepInput').addClass('active');
    }

    //값 유무에 따른 입력글자수 스타일 변경
    function textCountColor() {
        $('.text-form textarea, .text-form input[type=text]').each(function(){
            if($(this).val() == "") $(this).addClass('noData');
            else $(this).removeClass('noData');
    
            $(this).on('focusout blur', function(e){
                if($(this).val() == "") $(this).addClass('noData');
                else $(this).removeClass('noData');
            });
        });
    }
    textCountColor();


    $('.keyword-search .search-layer .keyword-box .keyword-list, .keyword-search .search-layer .autocomplete-box .keyword-list').mCustomScrollbar();

    //스스로해결 : 상세 '궁금한 점이 해결 되었나요?'
    $('button[linkid=survayPop]').on("click", function(e){ 
        $('[linkid=survaybox]').hide();
        $('#surveyPopup').show();
        var csaFlag = ($(this).prop('id') == 'btnSurvey1')?"Y":"N";
        if("Y"==csaFlag){
            $('[linkid=surveyContent1]').show();
            $('[linkid=surveyContent2]').hide();
            // BTOCSITE-55571 스스로 해결 콘텐츠 Survey 화면 변경 및 데이터 Tracking
            $('#surveyPopup .common-banner').hide();
            $('#surveyPopup .text-form .tit').hide();
            $('#surveyPopup .text-form textarea').prop('placeholder', '예시) "설명이 이해하기 쉬었어요.", "필요한 정보를 빠르게 얻었어요." 등 \n개인정보는 입력하지 않도록 주의해 주세요.');
        }else{
            $('[linkid=surveyContent1]').hide();
            $('[linkid=surveyContent2]').show();
            // BTOCSITE-55571 스스로 해결 콘텐츠 Survey 화면 변경 및 데이터 Tracking
            $('#surveyPopup .common-banner').show();
            $('#surveyPopup .text-form .tit').show();
            $('#surveyPopup .text-form textarea').prop('placeholder', '예시) "설명을 이해하기 어려웠어요.", "원하는 정보를 얻지 못했어요" 등 \n개인정보는 입력하지 않도록 주의해 주세요.  ');
        }
        $('#surveyPopup input[name=csaFlag]').val(csaFlag);
    });

    $('article#surveyPopup').on("click",'button.btn-confirm' ,function(e){
        $('[linkid=survayEndbox]').show();
        $('#surveyPopup').hide();
    });

    

    // $('.btn-datebefore').on('click', function(){
    //     $(this).closest('.contents').find('#stepDateBefore').addClass('open').show();
    //     $(this).closest('.contents').find('.step-btn-wrap').show();
    // });

    // $('.btn-cal').on('click', function(){
    //     $(this).closest('.contents').find('.step-btn-wrap').hide();
    //     $(this).closest('.contents').find('#stepDate').addClass('open').show();
    // });

   
});

$(window).on('load resize', function(){
    if ($(window).width() > 767){
        $('html').removeClass('scroll-fixed');
        $('body').removeClass('scroll-lock');
    }
});

// $(document).ready(function(){
//     $('.mobile-nav-button').trigger('click');
// });